import { Injectable } from '@angular/core';
import { Query } from 'appwrite';
import { databases } from '../../../../lib/appwrite';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor() {}

  async fetchCountries(): Promise<any> {
    try {
      const response = await databases.listDocuments(
        environment.appwrite.databaseId,
        environment.appwrite.countyCollectionId,
        [Query.orderAsc('name'), Query.limit(200), Query.offset(0)]
      );
      return response.documents;
    } catch (error) {
      console.error('Error fetching countries:', error);
      throw error;
    }
  }
}
