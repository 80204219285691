<div class="relative inline-block w-full">
    <button (click)="toggleDropdown()" class="w-full p-2 border rounded text-left">
      {{ selectedCategory?.name || 'Select a category' }}
    </button>
    <div *ngIf="dropdownOpen" class="absolute z-10 w-full bg-white border rounded shadow-lg dropdown-content" #dropdownContent>
      <div *ngFor="let category of categories" 
           (click)="selectCategory(category)" 
           class="flex items-center p-2 cursor-pointer hover:bg-gray-200" 
           [attr.data-letter]="category.name.charAt(0).toUpperCase()">
        <span class="whitespace-nowrap truncate">{{ category.name }}</span>
      </div>
    </div>
  </div>