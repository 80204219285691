import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gray-box',
  standalone: true,
  templateUrl: './gray-box.component.html',
  styleUrl: './gray-box.component.scss'
})
export class GrayBoxComponent {
  @Input() addTopMargin: boolean = false;

}

