import { Injectable } from '@angular/core';
import { Query } from 'appwrite';
import { databases } from '../../../../lib/appwrite';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor() { }

  async fetchCategories(): Promise<any> {
    try {
      const response = await databases.listDocuments(
        environment.appwrite.databaseId, 
        environment.appwrite.categoriesCollectionId,
        [
          Query.orderAsc('rank') 
        ]
      );
      return response.documents;
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw error;
    }
  }
}