<div class="relative inline-block w-full">
    <button (click)="toggleDropdown()" class="w-full p-2 border rounded text-left">
      {{ selectedCountry?.name || 'Select a country' }}
    </button>
    <div *ngIf="dropdownOpen" class="absolute z-10 w-full bg-white border rounded shadow-lg dropdown-content" #dropdownContent>
      <div *ngFor="let country of countries" (click)="selectCountry(country)" class="flex items-center p-2 cursor-pointer hover:bg-gray-200" [attr.data-letter]="country.name.charAt(0).toUpperCase()">
        <img [src]="country.flag" alt="{{ country.name }} flag" class="w-8 h-4 object-cover mr-2">
        <span class="whitespace-nowrap truncate">{{ country.name }}</span>
      </div>
    </div>
  </div>
  