import { Client, Databases, Account } from 'appwrite';
import { environment } from '../environments/environment';


const client = new Client();
client
  .setEndpoint(environment.appwrite.endpoint) // Your Appwrite Endpoint
  .setProject(environment.appwrite.projectId); // Your project ID

const databases = new Databases(client);
const account = new Account(client);

export { client, databases, account };