<header class="bg-blue-600 text-white p-4">
    <div class="container mx-auto flex justify-between items-center">
        <h1 class="text-xl font-bold"><i class="fas fa-file-alt mr-2"></i>Document Finder</h1>
        <div class="relative">
            <input type="text" placeholder="Search..." class="py-2 px-4 pr-10 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-300 text-gray-800">
            <button class="absolute right-0 top-0 mt-2 mr-3 text-gray-600">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>
</header>