import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { CountryService } from './services/country.service';
import { Country } from './interfaces/country.interface';

@Component({
  selector: 'app-countries',
  standalone: true,
  templateUrl: './countries.component.html',
  styleUrl: './countries.component.scss',
  imports: [CommonModule],
  providers: [CountryService],
})
export class CountriesComponent {
  constructor(private countryService: CountryService) {}

  @Output() countrySelected = new EventEmitter<string>();

  @ViewChild('dropdownContent', { static: false }) dropdownContent!: ElementRef;
  dropdownOpen = false;
  selectedCountry: any;
  countries: Country[] = [];

  ngOnInit() {
    this.fetchCategories();
  }

  ngAfterViewInit() {
    if (this.dropdownContent) {
      this.dropdownContent.nativeElement.focus();
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectCountry(country: any) {
    this.selectedCountry = country;
    this.dropdownOpen = false;
    this.countrySelected.emit(country);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const target = event.target as HTMLElement;

    // Check if dropdownContent is initialized and not null
    if (this.dropdownOpen && this.dropdownContent && !this.dropdownContent.nativeElement.contains(target)) {
      this.dropdownOpen = false;
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.dropdownOpen) {
      const letter = event.key.toUpperCase();
      const countryElement = this.dropdownContent.nativeElement.querySelector(`div[data-letter="${letter}"]`);
      if (countryElement) {
        countryElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }

  private async fetchCategories() {
    try {
      this.countries = await this.countryService.fetchCountries();
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }

}
