<app-header></app-header>

<main class="container mx-auto mt-8 px-4 flex-grow flex">
  <!-- Left sidebar for filters -->
  <aside class="w-1/4 pr-8">
      <h2 class="text-xl font-semibold mb-4">Filters</h2>
      <div class="space-y-4">
          <div>
              <h3 class="font-medium mb-2">Country</h3>
              <div class="space-y-2">
                <app-countries (countrySelected)="onCountrySelected($event)" class="block"></app-countries>
              </div>
              <h3 class="font-medium mb-2">Document Type</h3>
              <app-categories></app-categories>
          </div>
      </div>
      <app-gray-box [addTopMargin]="true"></app-gray-box>
  </aside>


  <!-- Main content area -->
  <div class="w-3/4">
      <!-- Google AdSense placeholder -->

      <div>
        <!-- Google AdSense placeholder -->
        <app-gray-box class="block"></app-gray-box>
        <!-- Main content area -->
      </div>
    

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <!-- Personal Documents -->
          <div class="bg-white p-6 rounded-lg shadow-md">
              <h2 class="text-xl font-semibold mb-4"><i class="fas fa-user mr-2 text-blue-600"></i>Personal Documents</h2>
              <ul class="space-y-2">
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-id-card mr-2"></i>Identification: Passport, National ID, Birth Certificate</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-plane-departure mr-2"></i>Travel: Visa, Travel Insurance, Itinerary</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-graduation-cap mr-2"></i>Education: Diplomas, Transcripts, Certificates</a></li>
              </ul>
          </div>
          <!-- Legal Documents -->
          <div class="bg-white p-6 rounded-lg shadow-md">
              <h2 class="text-xl font-semibold mb-4"><i class="fas fa-balance-scale mr-2 text-blue-600"></i>Legal Documents</h2>
              <ul class="space-y-2">
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-file-contract mr-2"></i>Contracts: Employment Contracts, Lease Agreements, Sales Contracts</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-gavel mr-2"></i>Court Documents: Judgments, Summons, Legal Notices</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-handshake mr-2"></i>Agreements: NDA, Power of Attorney, Partnership Agreements</a></li>
              </ul>
          </div>
          <!-- Financial Documents -->
          <div class="bg-white p-6 rounded-lg shadow-md">
              <h2 class="text-xl font-semibold mb-4"><i class="fas fa-wallet mr-2 text-blue-600"></i>Financial Documents</h2>
              <ul class="space-y-2">
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-university mr-2"></i>Banking: Bank Statements, Loan Agreements, Credit Reports</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-chart-line mr-2"></i>Investments: Stock Certificates, Investment Portfolios, Bonds</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-file-invoice-dollar mr-2"></i>Taxation: Tax Returns, Tax Forms, Property Tax Receipts</a></li>
              </ul>
          </div>
          <!-- Medical Documents -->
          <div class="bg-white p-6 rounded-lg shadow-md">
              <h2 class="text-xl font-semibold mb-4"><i class="fas fa-notes-medical mr-2 text-blue-600"></i>Medical Documents</h2>
              <ul class="space-y-2">
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-file-medical-alt mr-2"></i>Medical Records: Health Reports, Test Results, Vaccination Records</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-shield-alt mr-2"></i>Insurance: Health Insurance Policies, Claims, Reimbursement Forms</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-prescription-bottle-alt mr-2"></i>Prescriptions: Medication Prescriptions, Prescription History</a></li>
              </ul>
          </div>
          <!-- Employment Documents -->
          <div class="bg-white p-6 rounded-lg shadow-md">
              <h2 class="text-xl font-semibold mb-4"><i class="fas fa-briefcase mr-2 text-blue-600"></i>Employment Documents</h2>
              <ul class="space-y-2">
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-file-alt mr-2"></i>Resumes: CVs, Cover Letters</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-folder-open mr-2"></i>Employment Records: Employment Contracts, Performance Reviews, Salary Slips</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-certificate mr-2"></i>Certifications: Professional Certifications, Training Certificates, Awards</a></li>
              </ul>
          </div>
          <!-- Business Documents -->
          <div class="bg-white p-6 rounded-lg shadow-md">
              <h2 class="text-xl font-semibold mb-4"><i class="fas fa-building mr-2 text-blue-600"></i>Business Documents</h2>
              <ul class="space-y-2">
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-file-signature mr-2"></i>Company Formation: Articles of Incorporation, Business Licenses, Partnership Agreements</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-file-invoice-dollar mr-2"></i>Financials: Profit and Loss Statements, Balance Sheets, Invoices</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-tasks mr-2"></i>Operational: Business Plans, SOPs, Meeting Minutes</a></li>
              </ul>
          </div>
          <!-- Property Documents -->
          <div class="bg-white p-6 rounded-lg shadow-md">
              <h2 class="text-xl font-semibold mb-4"><i class="fas fa-home mr-2 text-blue-600"></i>Property Documents</h2>
              <ul class="space-y-2">
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-file-contract mr-2"></i>Real Estate: Property Deeds, Lease Agreements, Mortgage Papers</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-car mr-2"></i>Vehicle: Registration Documents, Sale Agreement, Insurance Policies</a></li>
              </ul>
          </div>
          <!-- Government Documents -->
          <div class="bg-white p-6 rounded-lg shadow-md">
              <h2 class="text-xl font-semibold mb-4"><i class="fas fa-landmark mr-2 text-blue-600"></i>Government Documents</h2>
              <ul class="space-y-2">
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-file-alt mr-2"></i>Applications: Permit Applications, Grant Applications, Licenses</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-file-alt mr-2"></i>Regulations: Compliance Documents, Guidelines, Policy Documents</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-envelope-open-text mr-2"></i>Correspondence: Official Letters, Notices, Circulars</a></li>
              </ul>
          </div>
          <!-- Miscellaneous Documents -->
          <div class="bg-white p-6 rounded-lg shadow-md">
              <h2 class="text-xl font-semibold mb-4"><i class="fas fa-folder mr-2 text-blue-600"></i>Miscellaneous Documents</h2>
              <ul class="space-y-2">
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-book mr-2"></i>Manuals: User Manuals, Instruction Guides, Safety Manuals</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-project-diagram mr-2"></i>Plans: Architectural Plans, Floor Plans, Project Plans</a></li>
                  <li><a href="#" class="text-blue-600 hover:underline"><i class="fas fa-file-alt mr-2"></i>Personal Records: Journals, Diaries, Personal Letters</a></li>
              </ul>
          </div>
      </div>
  </div>

  <!-- Submit Document Section -->
  <aside class="w-1/4 pl-8">
      <div class="bg-white p-4 rounded shadow-md">
          <h2 class="text-lg font-semibold mb-4 text-center">Submit New Document</h2>
          <div class="text-center">
              <button id="submitDocumentButton" class="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700">Submit Document</button>
          </div>
      </div>
      <app-gray-box [addTopMargin]="true"></app-gray-box>
  </aside>
</main>

<app-footer></app-footer>

<!-- Modal -->
<div id="submitDocumentModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center hidden">
  <div class="bg-white p-6 rounded-lg shadow-lg w-1/3">
      <h2 class="text-2xl font-semibold mb-4">Submit New Document</h2>
      <form action="/submit" method="post" class="space-y-4">
          <div>
              <label class="block mb-2 font-medium" for="documentTitle">Title</label>
              <input type="text" id="documentTitle" name="documentTitle" class="form-input w-full">
          </div>
          <div>
              <label class="block mb-2 font-medium" for="documentCountry">Country</label>
              <input type="text" id="documentCountry" name="documentCountry" class="form-input w-full">
          </div>
          <div>
              <label class="block mb-2 font-medium" for="documentLink">Link to Document</label>
              <input type="url" id="documentLink" name="documentLink" class="form-input w-full">
          </div>
          <div>
              <label class="block mb-2 font-medium" for="submitterName">Name</label>
              <input type="text" id="submitterName" name="submitterName" class="form-input w-full">
          </div>
          <div>
              <label class="block mb-2 font-medium" for="submitterFamilyName">Family Name</label>
              <input type="text" id="submitterFamilyName" name="submitterFamilyName" class="form-input w-full">
          </div>
          <div>
              <label class="block mb-2 font-medium" for="submitterEmail">Email</label>
              <input type="email" id="submitterEmail" name="submitterEmail" class="form-input w-full">
          </div>
          <div class="g-recaptcha" data-sitekey="your-site-key"></div>
          <div class="text-center">
              <button type="submit" class="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700">Submit</button>
          </div>
      </form>
  </div>
</div>