import { APP_INITIALIZER, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from './components/header/header.component';
import { GrayBoxComponent } from "./components/advertisement/gray-box.component";
import { CountriesComponent } from "./components/countries/countries.component";
import { CategoryComponent } from './components/categories/categories.component';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule, HeaderComponent, FooterComponent, GrayBoxComponent, CountriesComponent, CategoryComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor() {}

  selectedCountry: any = null;
  countryCode: string | null = null;

  onCountrySelected(country: any) {
    this.selectedCountry = country;
  }

}
