import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CategoryService } from './services/category.service';
import { Category } from './interfaces/category.interface';

@Component({
  selector: 'app-categories',
  standalone: true,
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.scss',
  imports: [FormsModule, CommonModule],
  providers: [CategoryService],
})
export class CategoryComponent {
  categories: Category[] = [];
  selectedCategory: any | null = null;
  @ViewChild('dropdownContent')
  dropdownContent!: ElementRef;
  dropdownOpen = false;

  constructor(private categoryService: CategoryService) { }

  ngOnInit() {
    this.fetchCategories();
  }

  async fetchCategories() {
    try {
      this.categories = await this.categoryService.fetchCategories();
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }

  onCategoryChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCategory = parseInt(selectElement.value, 10) || null;
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectCategory(category: any): void {
    this.selectedCategory = category;
    this.dropdownOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (!this.dropdownContent.nativeElement.contains(event.target) && this.dropdownOpen) {
      this.dropdownOpen = false;
    }
  }

}
